<template>
	<div class="file_box">
		<div class="table">
			<el-table
				border
				:data="file_list.slice(0, 20)"
				style="width: 100%"
				height="100%"
				@row-click="rowClick"
			>
				<el-table-column prop="fileId" label="fileId" width="200">
				</el-table-column>
				<el-table-column prop="fileName" label="文件名" width="800">
					<template slot-scope="scope">
						<span
							style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
						>
							{{ scope.row.fileName }}
						</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="120">
					<template slot-scope="scope">
						<el-button
							type="text"
							style="color: red;"
							@click.stop="remove(scope.row)"
						>
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script setup>
import { ref } from "vue";
import api from "@/api/methods/index.js";
import { Message } from "element-ui";
import dingDisk from "@/utils/ding_disk";

const file_list = ref([]);
const getFileList = async () => {
	api.login
		.getAllFile({
			type: 0,
		})
		.then((msg) => {
			if (msg.data.state == 1) {
				file_list.value = msg.data.row;
			}
		});
};
getFileList();

const remove = async (row) => {
	const index = file_list.value.findIndex((item) => item.fileId == row.fileId);
	if (index != -1) {
		await api.login.delFile({
			file_id: row.fileId,
		});
		file_list.value.splice(index, 1);
		Message.success("删除成功");
	}
};

const rowClick = (row) => {
	dingDisk.previewFile(row);
};
</script>

<style lang="less" scoped>
.file_box {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	.table {
		flex: 1;
		overflow: hidden;
	}
}
</style>
